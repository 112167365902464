<template>
    <div class="grid crud-demo">
        <Dialog v-model:visible="editInvoice" :closable="true" :modal="true" :breakpoints="{ '960px': '75vw', '640px': '90vw' }" :style="{ width: '65vw' }" :maximizable="true">
            <LinkPagoUI :id="editCard.id" @updated="showLinkPago" @deleted="showLinkPago" @selected="showLinkPago" />
        </Dialog>
        <Toast />
        <Loader v-model="loading" />

        <div class="card">
            <Toast />
            <Panel header="Reportes de almacen">
                <BasicFormToolbar @refresh="refresh" @list="list" :actions="['list', 'refresh']" />
                <Fieldset :toggleable="true" legend="Parametros">
                    <div class="p-fluid p-formgrid grid">
                        <FormDropdown :wrapperClass="'field col-4'" :label="'Reportes'" v-model="report_type" :options="report" :optionLabel="'name'" :optionValue="'id'" />
                        <div v-show="this.report_type == 1|| this.report_type == 2 || this.report_type == 4 " class="field col-4">
                            <label>Fecha Inicial</label>
                            <Calendar dateFormat="dd/mm/yy" v-model="entity.initial_date" :showIcon="true" :class="{ 'p-invalid': validate.validations.initial_date === false }" />
                            <small class="p-invalid" v-if="validate.validations.initial_date === false"> Favor de llenar el campo </small>
                        </div>
                        <div class="field col-4" v-show="this.report_type == 1|| this.report_type == 2 || this.report_type == 4 ">
                            <label>Fecha Final</label>
                            <Calendar dateFormat="dd/mm/yy" v-model="entity.final_date" :showIcon="true" :class="{ 'p-invalid': validate.validations.final_date === false }" />
                            <small class="p-invalid" v-if="validate.validations.final_date === false"> Favor de llenar el campo </small>
                        </div>
                    </div>
                </Fieldset>
                <br />
                <div class="p-fluid p-formgrid grid">
                    <div class="field col-12">
                        <Fieldset :toggleable="true" legend="Resultados">
                            <BasicDatatable
                                :menuModel="[{ label: 'Borrar Token', icon: 'pi pi-times-circle', action: 'deleteToken' }]"
                                @deleteToken="deleteToken"
                                :contextMenu="true"
                                :rowaction="true"
                                :headers="headers"
                                :rows="entities"
                                :rowGroupMode="'subheader'"
                                :groupRowsBy="groupRowsBy"
                                :rowSubgroupMode="rowsubgroupRowsBy"
                                :subgroupRowsBy="subgroupRowsBy"
                            />
                        </Fieldset>
                    </div>
                </div>
            </Panel>
        </div>
    </div>
</template>
<script>
import Session from '../../../mixins/sessionMixin';
import { Rule, ErrorToast, validate, Toast } from '../../../utilities/General';
import Loader from '../../../components/general/Loader.vue';
import FormDropdown from '../../../components/general/FormDropdown.vue';
import { FilterMatchMode, FilterOperator } from 'primevue/api';
import BasicFormToolbar from '../../../components/general/BasicFormToolbar.vue';
import BasicDatatable from '../../../components/general/BasicDatatable.vue';
import { InventoryMovementWarehouse } from '../../../models/inventarios/InventoryMovementWarehouse';

import { REPORT_SALDOS, REPORT_KARDEX, REPORT_INVENTARIO, REPORT_COSTO_PROMEDIO ,REPORT_MAX_MIN} from '../../../utilities/BW_UTILITIES';
import { InventoryMovementWarehouseItems } from '../../../models/inventarios/InventoryMovementWarehouseItems';
import { InventoryConfiguration } from '../../../models/inventarios/InventoryConfiguration';
import { InventoryMovementWarehouseUEPS } from '../../../models/inventarios/InventoryMovementWarehouseUEPS';
import { InventoryMovementWarehouseItemsUEPS } from '../../../models/inventarios/InventoryMovementWarehouseItemsUEPS';

export default {
    mixins: [Session],
    data() {
        return {
            editInvoice: false,
            report_type: null,
            // editCard: new PaymentParameters(this.session),
            entity: {
                id: '0',
                id_customer: 0,
                initial_date: new Date(),
                final_date: new Date(),
            },
            report: null,
            entities: [],
            tags: [],
            customers: [],
            companyDB: [],

            filters: null,
            loading: false,
            loadingInputs: false,
            company_val: null,
            companydb_val: null,

            rules: [new Rule({ name: 'initial_date' }), new Rule({ name: 'final_date' })],
            validate: {
                valid: false,
                validations: {
                    initial_date: null,
                    final_date: null,
                },
            },
        };
    },
    computed: {
        groupRowsBy() {
            return this.report_type == 4 ? ''  :this.report_type == 3 ? 'strwarehouse':this.report_type == 5 ? 'strwarehouse' :this.report_type == 6 ? 'strwarehouse': 'strdescriptionwarehouse';
        },
        subgroupRowsBy() {
            return this.report_type == 4 ? 'coust' : 'final_total';
        },
        rowsubgroupRowsBy() {
            return this.report_type == 4 ? null : "'SUM'";
        },

        headers() {
            if (this.report_type == 1) {
                return REPORT_SALDOS['ACCEPT'] ? REPORT_SALDOS['ACCEPT'] : [];
            } else if (this.report_type == 2) {
                return REPORT_KARDEX['ACCEPT'] ? REPORT_KARDEX['ACCEPT'] : [];
            } else if (this.report_type == 3) {
                return REPORT_INVENTARIO['ACCEPT'] ? REPORT_INVENTARIO['ACCEPT'] : [];
            } else  if (this.report_type == 4){
                return REPORT_COSTO_PROMEDIO['ACCEPT'] ? REPORT_COSTO_PROMEDIO['ACCEPT'] : [];
            }
            else if(this.report_type == 5){
                return REPORT_MAX_MIN['ACCEPT'] ?  REPORT_MAX_MIN['ACCEPT'] : [];
            }
              else {
                return REPORT_MAX_MIN['ALMACEN'] ?  REPORT_MAX_MIN['ALMACEN'] : [];
            }
        },
    },
    components: { Loader, BasicFormToolbar, BasicDatatable, FormDropdown },

    async created() {
        this.initFilters();
    },

    async mounted() {
        await this.refresh();
        //  this.companyDB= await new UniverseTransaction(this.session).companyDB()
    },
    methods: {
        copy(payload) {
            if (payload.paymentLink) {
                navigator.clipboard.writeText(payload.paymentLink);
                this.$toast.add(
                    new Toast({
                        summary: 'Link de Pago',
                        detail: 'Se ha copiado el link en el portapapeles',
                    })
                );
            } else {
                this.$toast.add(new ErrorToast('No se ha generado el URL'));
            }
        },
        async deleteToken(entities) {
            console.log(entities);
        },

        formatDate(value) {
            return new Date(value).toLocaleDateString('es-ES');
        },

        exportCSV() {
            this.$refs.dt.exportCSV();
        },
        showLinkPago(data) {
            this.editCard.id = data.id;
            this.editInvoice = true;
        },
        initFilters() {
            this.filters = {
                global: { value: null, matchMode: FilterMatchMode.CONTAINS },
                id: {
                    operator: FilterOperator.AND,
                    constraints: [{ value: null, matchMode: FilterMatchMode.CONTAINS }],
                },
                invoiceInformation_descrption: {
                    operator: FilterOperator.AND,
                    constraints: [{ value: null, matchMode: FilterMatchMode.CONTAINS }],
                },
                paymentLink: {
                    operator: FilterOperator.AND,
                    constraints: [{ value: null, matchMode: FilterMatchMode.CONTAINS }],
                },
                invoiceInformation_dueDate: {
                    operator: FilterOperator.AND,
                    constraints: [{ value: null, matchMode: FilterMatchMode.CONTAINS }],
                },
                Estatus: {
                    operator: FilterOperator.AND,
                    constraints: [{ value: null, matchMode: FilterMatchMode.CONTAINS }],
                },
            };
        },
        async list() {
            this.loading = true;
            this.editInvoice = false;
            try {
                //validacion de campos
                this.validate = validate(this.entity, this.rules);
                if (!this.validate.valid) throw 'Favor de validar los campos';

                var date2 = this.entity.final_date;

                const formatDate2 = (date2) => {
                    let formatted_date2 = date2.getFullYear() + '-' + (date2.getMonth() + 1) + '-' + date2.getDate() + ' ' + '23:59:59';
                    return formatted_date2;
                };
                this.configuration = await new InventoryConfiguration(this.session).getCustom();
                var conf = this.configuration[0].value;
                if (conf == 1) {
                    if (this.report_type == 1) {
                        this.entities = await new InventoryMovementWarehouse(this.session).getAdminMov(formatDate2(this.entity.initial_date), formatDate2(date2));
                    } else if (this.report_type == 2) {
                        this.entities = await new InventoryMovementWarehouse(this.session).getReportkardex(formatDate2(this.entity.initial_date), formatDate2(date2));
                    } else if (this.report_type == 3) {
                        this.entities = await new InventoryMovementWarehouse(this.session).getInventary();
                    } else if (this.report_type == 4) {
                        this.entities = await new InventoryMovementWarehouseItems(this.session).getReportPromArt();
                    }else if(this.report_type == 5) {
                         this.entities = await new InventoryMovementWarehouse(this.session).getPedidoMaxMin();
                    }
                       else if(this.report_type == 6) {
                         this.entities = await new InventoryMovementWarehouse(this.session).getPedidoMaxMinAlmacen();
                    }
                } else {
                    if (this.report_type == 1) {
                        this.entities = await new InventoryMovementWarehouseUEPS(this.session).getAdminMovUEPS(formatDate2(this.entity.initial_date), formatDate2(date2));
                    } else if (this.report_type == 2) {
                        this.entities = await new InventoryMovementWarehouseUEPS(this.session).getReportkardex(formatDate2(this.entity.initial_date), formatDate2(date2));
                    } else if (this.report_type == 3) {
                        this.entities = await new InventoryMovementWarehouseUEPS(this.session).getInventary();
                    } else if (this.report_type == 4) {
                        this.entities = await new InventoryMovementWarehouseItemsUEPS(this.session).getReportPromArt();
                    }
                    else if(this.report_type == 5) {
                         this.entities = await new InventoryMovementWarehouse(this.session).getPedidoMaxMin();
                    }
                     else if(this.report_type == 6) {
                         this.entities = await new InventoryMovementWarehouse(this.session).getPedidoMaxMinAlmacen();
                    }
                }
                console.log(this.entities);
            } catch (error) {
                this.$toast.add(new ErrorToast(error));
            } finally {
                this.loading = false;
            }
        },
        async refresh() {
            this.loading = true;
            try {
                this.entity = {
                    id_customer: 0,
                    initial_date: new Date(),
                    final_date: new Date(),
                };
                this.configuration = await new InventoryConfiguration(this.session).getCustom();
                var conf = this.configuration[0].value;

                if (conf == 1) {
                    this.report = [
                        { id: 1, name: 'REPORTE SALDOS PEPS' },
                        { id: 2, name: 'REPORTE KARDEX' },
                        { id: 3, name: 'REPORTE INVENTARIO' },
                        { id: 4, name: 'REPORTE COSTO PROMEDIO' },
                        { id: 5, name: 'REPORTE PEDIDO X MAX-MIN SUBALMACEN' },
                             { id: 6, name: 'REPORTE PEDIDO X MAX-MIN ALMACEN' },
                    ];
                } else {
                    this.report = [
                        { id: 1, name: 'REPORTE SALDOS UEPS' },
                        { id: 2, name: 'REPORTE KARDEX' },
                        { id: 3, name: 'REPORTE INVENTARIO' },
                        { id: 4, name: 'REPORTE COSTO PROMEDIO' },
                        { id: 5, name: 'REPORTE PEDIDO X MAX-MIN SUBALMACEN' },
                        { id: 6, name: 'REPORTE PEDIDO X MAX-MIN ALMACEN' },
                    ];
                }

                // await this.list();
            } catch (error) {
                this.$toast.add(new ErrorToast(error));
            } finally {
                this.loading = false;
            }
        },
    },
};
</script>
<style scoped lang="scss">
.table-header {
    display: flex;
    justify-content: space-between;
}

.confirmation-content {
    display: flex;
    align-items: center;
    justify-content: center;
}

@media screen and (max-width: 960px) {
    ::v-deep(.p-toolbar) {
        flex-wrap: wrap;

        .p-button {
            margin-bottom: 0.25rem;
        }
    }
    .p-chip.custom-chip {
        background: var(--primary-color);
        color: var(--primary-color-text);
    }
    .p-chip.tag-chip {
        background: #2c9663;
        color: var(--primary-color-text);
    }
}
</style>