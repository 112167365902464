import axios from "axios";

import { Abstract } from './Abstract'; 
  export class InventoryMovementWarehouseItemsUEPS extends Abstract 
  { 
  constructor(session) { 
  super('Inventory/InventoryMovementWarehouseUEPS', session) ;
  this.number = null;
this.id_movementwarehouse=null;
this.id_movementtype=null;
this.id_article=null;
this.id_warehouse=null;
this.id_subwarehouse=null;
this.productName=null;
this.number=null
this.quantity=null;
this.coust=null;


    }  
    async allitem() {
     
        let response = await axios.get(this.controller + '/allitem', {
            headers: {
                user: this.user,
                company: this.id_company,
                branch: this.id_branch
            }
        });
        return response.data;
    }

    
    async getReportPromArt() {
        let response = await axios.get(this.controller + "/ReportPromArt", {
            headers: {
                user: this.user,
                company: this.id_company,
                branch: this.id_branch
            }
        });
        return response.data;
      }

      async postInventoryAdjustUEPS(){
        let response = await axios.post(this.controller + "/postInventoryAdjustUEPS", this, {
            headers: {
                user: this.user,
                company: this.id_company,
                branch: this.id_branch,
            }
        });
        return response.data;
    }
 } 
 